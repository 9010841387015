import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { firebaseConnect, getVal } from "react-redux-firebase";
import { compose } from "redux";
import {
  PageTitle,
  Container,
  CenterWrapper,
} from "app/components/RouteComponents";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { ClassList } from "app/components/ClassList";
import { Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { routePadding } from "styles/variables";

const ClassesWrapper = styled.div`
  ${!isMobile && (({ theme }) => theme.mediaQueries.ltmd)} {
    padding: 0 ${routePadding}px;
  }
`;
const Purchased = ({ classes, requested }) => (
  <CenterWrapper>
    <Helmet title="Purchased | Online Dance Classes and Tutorials" />
    {!requested && <LoaderCentered />}
    {requested && !classes.length && <Redirect to="/dashboard" />}
    {requested && !!classes.length && (
      <Container>
        <PageTitle pb={[4]}>Purchased Classes</PageTitle>
        <ClassesWrapper>
          <ClassList mt={2} classes={classes} selectedFrom="Purchased" />
        </ClassesWrapper>
      </Container>
    )}
  </CenterWrapper>
);

const mapStateToProps = ({ firebase, user }) => ({
  requested: firebase.requested.classes_details,
  classes: _.reduce(
    user.private.purchased_classes,
    (arr, classId) => {
      const classData = getVal(firebase, `data/classes_details/${classId}`);
      if (classData) {
        arr.push(classData);
      }

      return arr;
    },
    []
  ),
});

Purchased.defaultProps = {
  classes: null,
  requested: false,
};

Purchased.propTypes = {
  classes: PropTypes.instanceOf(Array),
  requested: PropTypes.bool,
};

export default compose(
  connect(mapStateToProps),
  firebaseConnect(["classes_details"])
)(Purchased);
